import _export from "../internals/export";
import _fails from "../internals/fails";
import _toObject from "../internals/to-object";
import _objectGetPrototypeOf from "../internals/object-get-prototype-of";
import _correctPrototypeGetter from "../internals/correct-prototype-getter";
var $ = _export;
var fails = _fails;
var toObject = _toObject;
var nativeGetPrototypeOf = _objectGetPrototypeOf;
var CORRECT_PROTOTYPE_GETTER = _correctPrototypeGetter;
var FAILS_ON_PRIMITIVES = fails(function () {
  nativeGetPrototypeOf(1);
});

// `Object.getPrototypeOf` method
// https://tc39.es/ecma262/#sec-object.getprototypeof
$({
  target: "Object",
  stat: true,
  forced: FAILS_ON_PRIMITIVES,
  sham: !CORRECT_PROTOTYPE_GETTER
}, {
  getPrototypeOf: function getPrototypeOf(it) {
    return nativeGetPrototypeOf(toObject(it));
  }
});
export default {};